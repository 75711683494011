import React, { ReactElement } from 'react';
import { Container } from 'react-bootstrap';
import { Layout } from '../components/common';
import ContentImageRow from '../components/common/ContentImageRow';
import BannerSection from '../components/common/contentSections/BannerSection';
import ContentHero from '../components/common/contentSections/ContentHero';
import HeadingSection from '../components/common/contentSections/HeadingSection';
import VideoSection from '../components/common/contentSections/VideoSection';
import ImageContentRow from '../components/common/ImageContentRow';
import { ContentData } from '../utils/entities';

const DataVerificationContentData: ContentData[] = [
    {
        id: 1,
        title: `Access Control`,
        desc: `Give authorization and revoke access to any one at any time from anywhere in the world.`,
        imageUrl: `images/data-verification-access-control@3x.png`,
    },
    {
        id: 2,
        title: `Increase productivity`,
        desc: `Work better by focusing on important tasks by gaining quick access to tools and apps you need without going through time consuming sign in process.`,
        imageUrl: `images/data-verification-productivity.svg`,
    },
    {
        id: 3,
        title: `Strengthen security`,
        desc: `Use automated policies to reduce human error risk that comes with manual processes.`,
        imageUrl: `images/data-verification-security.svg`,
    },
];

const DataVerification = (): ReactElement => (
    <Layout>
        <Container>
            <ContentHero
                heading="Data verification & Management"
                desc="Verify and authenticate all your information, manage access, secure and customize user login flow "
                buttonText="Get Started"
                imageUrl="/images/data-verification-hero.svg"
            />

            <HeadingSection
                heading="Features"
                subheading="Data verification & Management"
            />
            {DataVerificationContentData.map((item) => {
                if (item.id % 2 === 0) {
                    return <ContentImageRow key={item.id} {...item} />;
                } else {
                    return <ImageContentRow key={item.id} {...item} />;
                }
            })}
            <VideoSection />
            <BannerSection />
        </Container>
    </Layout>
);

export default DataVerification;
